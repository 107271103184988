import type {
  HeadlineResponse,
  NilHundredResponseNil100FilterResponseRelatedPagedData,
  PlayerItemResponsePagedData,
  ScoreboardResponseRelatedScheduleResponseRelatedPagedData,
  SimpleHeadlineResponseSimpleSiteResponseRecordRelatedPagedData,
} from '@on3/api';
import { ISiteContext } from '@on3/ui-lib/contexts/SiteProvider';
import withAuthentication from '@on3/ui-lib/src/hocs/withAuthentication';
import withValidUrl from '@on3/ui-lib/src/hocs/withValidUrl';
import { proxyApi } from '@on3/ui-lib/utils/api';
import { FeedHome, IFeedHomeProps } from 'components/Home/FeedHome';
import {
  ITraditionalHomeProps,
  TraditionalHome,
} from 'components/Home/TraditionalHome';
import { PageWrapper } from 'components/Layouts/PageWrapper';
import type { GetServerSideProps } from 'next';

interface ITeamResponse extends IFeedHomeProps, ITraditionalHomeProps {
  siteData?: ISiteContext;
}

export const TeamPage = ({ siteData, ...props }: ITeamResponse) => {
  const { currentSite } = siteData || {};

  const { template } = currentSite || {};
  const { organization, siteName, url: siteUrl } = currentSite || {};
  const title = `${siteName}: ${organization?.name} ${organization?.mascot} Football & Basketball`;
  const description = `The latest ${organization?.name} ${organization?.mascot} news, recruiting, transfers, and NIL information at ${siteName}, part of on3.com`;

  if (template === 'Feed') {
    return (
      <PageWrapper
        {...props}
        canonical={`https://www.on3.com${siteUrl}/`}
        description={description}
        siteData={siteData}
        title={title}
      >
        <FeedHome {...props} />
      </PageWrapper>
    );
  }

  return (
    <PageWrapper
      {...props}
      canonical={`https://www.on3.com${siteUrl}/`}
      description={description}
      siteData={siteData}
      title={title}
    >
      <TraditionalHome {...props} />
    </PageWrapper>
  );
};

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  const siteData = await withValidUrl.isValid(ctx);
  const userData = await withAuthentication.isAuth(ctx);
  const ksrPlusSiteKey = 395;
  const defaultYear = siteData?.defaultYears?.footballSeasonYear;
  const params = {
    year: defaultYear,
    sportKey: 2,
  };

  if (
    (!siteData || !siteData?.currentSite?.live) &&
    !userData?.rg?.includes('developer')
  ) {
    return {
      notFound: true,
    };
  }

  const { currentSite } = siteData || {};

  const main = currentSite?.siteCategories?.find(
    (category) => category.url === '',
  );

  if (!main?.categoryKey) {
    console.error({ url: ctx?.req?.url, error: 'category null' });

    return {
      notFound: true,
    };
  }

  let data = {};

  try {
    if (currentSite?.template === 'Feed') {
      // KSR
      const [
        featuredArticles,
        mainFeed,
        channelSports,
        channelRecruiting,
        premiumArticles,
        messageBoard,
        teamSchedule,
      ] = await Promise.allSettled([
        // Featured Posts (2 latests/2 featured)
        proxyApi
          .get<HeadlineResponse[]>(
            `/content/v1/categories/${main?.categoryKey}/featured-headlines/`,
          )
          .then((r) => r.data),
        // Main news Feed
        proxyApi
          .get(
            `/content/v1/categories/${main?.categoryKey}/headlines-paginated/`,
          )
          .then((r) => r.data),
        //Channel Sports
        proxyApi
          .get(
            `/content/v1/sites/${currentSite?.siblingSiteKey}/headlines-for-category-type?type=Sport`,
          )
          .then((r) => r.data),
        //Channel Recruting
        proxyApi
          .get(
            `/content/v1/sites/${currentSite?.siblingSiteKey}/headlines-for-category-type?type=Recruiting`,
          )
          .then((r) => r.data),
        // Premium Articles
        proxyApi
          .get<SimpleHeadlineResponseSimpleSiteResponseRecordRelatedPagedData>(
            `/content/v1/sites/premium-headlines?siteKey=${ksrPlusSiteKey}`,
          )
          .then((r) => r.data),
        // Message board posts
        proxyApi
          .get(`/sites/v1/sites/${ksrPlusSiteKey}/forums/latest-posts`)
          .then((r) => r.data),
        proxyApi
          .get<ScoreboardResponseRelatedScheduleResponseRelatedPagedData>(
            `/rdb/v2/organizations/${currentSite?.organizationKey}/schedule`,
            { params },
          )
          .then((r) => r.data),
      ]);

      data = {
        siteData,
        userData: userData || {},
        featuredArticles:
          featuredArticles.status === 'fulfilled' ? featuredArticles.value : {},
        mainFeed: mainFeed.status === 'fulfilled' ? mainFeed.value : {},
        channelSports:
          channelSports.status === 'fulfilled' ? channelSports.value : {},
        channelRecruiting:
          channelRecruiting.status === 'fulfilled'
            ? channelRecruiting.value
            : {},
        premiumArticles:
          premiumArticles.status === 'fulfilled' ? premiumArticles.value : {},
        messageBoard:
          messageBoard.status === 'fulfilled' ? messageBoard.value : {},
        teamSchedule:
          teamSchedule.status === 'fulfilled' ? teamSchedule.value : {},
      };
    } else {
      //Traditional
      const [
        featuredArticles,
        siteSports,
        siteRecruiting,
        channelSports,
        channelRecruiting,
        messageBoard,
        latestCommits,
        teamNil100,
        premiumArticles,
        teamSchedule,
      ] = await Promise.allSettled([
        // Featured Articles
        proxyApi
          .get<HeadlineResponse[]>(
            `/content/v1/categories/${main?.categoryKey}/featured-headlines/`,
          )
          .then((r) => r.data),
        //Site Sports
        proxyApi
          .get(
            `/content/v1/sites/${currentSite?.key}/headlines-for-category-type?type=Sport`,
          )
          .then((r) => r.data),
        //Site Recruting
        proxyApi
          .get(
            `/content/v1/sites/${currentSite?.key}/headlines-for-category-type?type=Recruiting`,
          )
          .then((r) => r.data),
        //Channel Sports
        proxyApi
          .get(
            `/content/v1/sites/${currentSite?.siblingSiteKey}/headlines-for-category-type?type=Sport`,
          )
          .then((r) => r.data),
        //Channel Recruting
        proxyApi
          .get(
            `/content/v1/sites/${currentSite?.siblingSiteKey}/headlines-for-category-type?type=Recruiting`,
          )
          .then((r) => r.data),

        // Message board posts
        proxyApi
          .get(`/sites/v1/sites/${currentSite?.key}/forums/latest-posts`)
          .then((r) => r.data),
        // Latest Commits
        // todo: move these to params and make 2023 dynamic
        proxyApi
          .get<PlayerItemResponsePagedData>(
            `/public/rdb/v2/latest?orgKey=${currentSite?.organizationKey}`,
          )
          .then((r) => r.data),
        proxyApi
          .get<NilHundredResponseNil100FilterResponseRelatedPagedData>(
            `/public/rdb/v2/nil-100?orgKey=${currentSite?.organizationKey}`,
          )
          .then((r) => r.data),
        proxyApi
          .get<SimpleHeadlineResponseSimpleSiteResponseRecordRelatedPagedData>(
            `/content/v1/sites/premium-headlines?siteKey=${currentSite?.key}`,
          )
          .then((r) => r.data),
        proxyApi
          .get<ScoreboardResponseRelatedScheduleResponseRelatedPagedData>(
            `/rdb/v2/organizations/${currentSite?.organizationKey}/schedule`,
            { params },
          )
          .then((r) => r.data),
      ]);

      data = {
        siteData: siteData || [],
        userData: userData || {},
        featuredArticles:
          featuredArticles.status === 'fulfilled' ? featuredArticles.value : [],
        siteSports: siteSports.status === 'fulfilled' ? siteSports.value : [],
        siteRecruiting:
          siteRecruiting.status === 'fulfilled' ? siteRecruiting.value : [],
        channelSports:
          channelSports.status === 'fulfilled' ? channelSports.value : [],
        channelRecruiting:
          channelRecruiting.status === 'fulfilled'
            ? channelRecruiting.value
            : [],
        messageBoard:
          messageBoard.status === 'fulfilled' ? messageBoard.value : {},
        latestCommits:
          latestCommits.status === 'fulfilled' ? latestCommits.value : {},
        teamNil100: teamNil100.status === 'fulfilled' ? teamNil100.value : {},
        premiumArticles:
          premiumArticles.status === 'fulfilled' ? premiumArticles.value : {},
        teamSchedule:
          teamSchedule.status === 'fulfilled' ? teamSchedule.value : {},
      };
    }

    return { props: { ...data, pageType: 'home' } };
  } catch (e) {
    // console.log(e);
    return { props: {} };
  }
};

export default withValidUrl(TeamPage);
